<template>
    <div>
        <h2>{{ $t("tiers.factures_fournisseurs") }}</h2>

        <ShutterPanel ref="shutterPanel" />
    </div>
</template>


<script type="text/javascript">	
	import ShutterPanel from "GroomyRoot/components/Utils/ShutterPanel"
	
    import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js";
    import Shutter from "@/mixins/Shutter.js"
    import Tiers from "@/mixins/Tiers.js"

	export default {
		name: "SupplierInvoice",
		mixins: [Invoice, Navigation, Shutter, Tiers],
		props: {
			tiers_id: Number
		},
		data () {
			return {
                events_tab: {
					'TableAction::goToModalAddSupplierInvoice': this.openAddInvoiceSupplierShutter,
					'TableAction::goToEditSupplierInvoice': this.modifyInvoiceSupplierShutter,
					'TableAction::goToSeeSupplierInvoiceDetails': this.seeInvoiceSupplierDetailsShutter,
					
                }
			}
		},
		computed: {
			shutters() {
				return {
					'invoice-suppliers-list': {
                        name: 'invoice-suppliers-list',
                        title: this.$t('tiers.factures_fournisseurs_title'),
						cmpPath: 'components/ShutterScreens/Tier/InvoiceSupplierList',
                        props: {
                            tiers_id: this.tiers_id,
                            handleInvoiceOperations: this.reloadSuccessSaved
                        }
					},
					'invoice-suppliers-add': {
                        name: 'invoice-suppliers-add',
                        title: this.$t('tiers.ajout_facture_fournisseur'),
						cmpPath: 'components/ShutterScreens/Tier/InvoiceSupplierAdd',
                        props: {
							tiers_id: this.tiers_id,
							invoice_id: null
						},
						onOk: this.addInvoiceCompleted,
						onCancel: this.cancelAdd
                    },
					'invoice-suppliers-horse': {
                        name: 'invoice-suppliers-horse',
                        title: this.$t('tiers.article_refacturer'),
						cmpPath: 'components/ShutterScreens/Tier/InvoiceSupplierHorse',
                        props: {
							tiers_id: this.tiers_id,
							invoice: this.invoice,
							increment: 0,
						},
						onOk: this.associateHorseCompleted,
						onCancel: this.cancelAdd
					},
					'invoice-suppliers-details': {
                        name: 'invoice-suppliers-details',
                        title: this.$t('tiers.article_refacturer'),
						cmpPath: 'components/ShutterScreens/Tier/InvoiceSupplierDetails',
                        props: {
							invoice: this.invoice
						},
						onOk: this.addInvoiceCompleted,
					}
				}
			}
		},

		mounted() {
            this.init_component()
		},

		methods: {
            init_component() {
                this.shutterPanel().open(this.shutters['invoice-suppliers-list'])
            },

			openAddInvoiceSupplierShutter() {
				this.shutterPanel().keepFirst()
                this.shutterPanel().open(this.shutters['invoice-suppliers-add'])
			},

			addInvoiceCompleted(params) {
				this.shutterPanel().open(this.shutters['invoice-suppliers-horse'])
                this.shutterPanel().update('invoice-suppliers-horse', 'invoice', params)
			},

			associateHorseCompleted() {
				this.shutterPanel().reload('invoice-suppliers-list')
				this.shutterPanel().keepFirst()
			},

			modifyInvoiceSupplierShutter(params) {
				this.shutterPanel().open(this.shutters['invoice-suppliers-add'])
                this.shutterPanel().update('invoice-suppliers-add', 'invoice_id', params.invoicesuppliers_id)
			},

			seeInvoiceSupplierDetailsShutter(params) {
				this.shutterPanel().open(this.shutters['invoice-suppliers-details'])
                this.shutterPanel().update('invoice-suppliers-details', 'invoice', params)
			},
        
		},
		components: {
            ShutterPanel
		}
	}
</script>
